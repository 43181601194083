import * as React from 'react';

import { SfCustomerMyAccountModel } from "../classes/SfCustomer";
import { ObserverModel } from '../classes/ObserverModel';
import {DeptDealsNewsFeedRawData} from '../classes/DeptDealsNewsFeedRawData'
export class CustomerMainComponent extends React.Component<any, any>{

    constructor(props: any) {
        super(props);
        this.state = {
            value: null,
            isLoggedIn: false,
            sfCustomer: null,
            stateObserver: ObserverModel,
            contextState: null,
            detpDeals: null,
            recommendedProducts: null
        };
    }

    private static readonly SfCustomerCheckLoginURL = "webservicesCORE1/SfCustomer/CheckLogin";
    private static readonly GetDeptDealsNewsFeedURL = "webservicesCORE1/marketing/GetDeptDealsNewsFeed";
    private static readonly RecommendedProductsUrl = "webservicesCORE1/Product/GetRecommended";
    render() {
        return <div className="home__row">
            {this.getCustomerGreetingContents()}
            {this.getDealsOfTheWeek()}
        </div>
    }

    getCustomerGreetingContents() {
        let contents = this.state.isLoggedIn ?
            CustomerMainComponent.renderContents(this.state.sfCustomer, this.state.recommendedProducts, this.state.stateObserver, this.state.contextState) : "";
        return contents;
    }



    getDealsOfTheWeek() {
        let contents = this.state.detpDeals !== null ?
        CustomerMainComponent.renderDealsOfTheWeekContents(this.state.detpDeals) : <></>;
    return contents;
    }



    private static renderDealsOfTheWeekContents(deptDealsArray: DeptDealsNewsFeedRawData[]) {
       /*<img className="deals-card__image" src={"imgs/sale-banners/salebanner-" + deptDeal.DeptID + ".jpg"} />*/  /*ID-3555*/
        return <div className="home-card home-card--deals">
            <div className="home-card__header">
                <h1>{(window as any).stringResourcesHomepage.dealsOfTheWeek}</h1>
            </div>            
            <div className="home-card__body">
                <div className="product-slider-controls" id="deals-slider-controls">
                    <a className="product-slider-control product-slider-control--prev">
                        <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            width="10px" height="7.1px" viewBox="0 0 10 7.1" xmlSpace="preserve">
                        <g id="Layer_1">
	                        <polygon className="st0" points="10,0 10,0 5,4 0,0 0,3 5,7.1 5,7.1 10,3"/>
                        </g> 
                        </svg>
                    </a>
                    <a className="product-slider-control product-slider-control--next">
                        <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            width="10px" height="7.1px" viewBox="0 0 10 7.1" xmlSpace="preserve">
                            <g id="Layer_1">
                                <polygon className="st0" points="10,0 10,0 5,4 0,0 0,3 5,7.1 5,7.1 10,3" />
                            </g>
                        </svg>
                    </a>
                </div>
                <div className="product-slider product-slider--large product-slider--deals">
                    {deptDealsArray.map(deptDeal =>
                        <div key={deptDeal.NEWSFEED_ID} className="product-slider__slide">                            
                            <a href={deptDeal.URL} className="deals-card">
                                <img className="deals-card__image" src={deptDeal.IMAGE_URL} alt={deptDeal.IMAGE_ALT_TAG} />
                                <h3 className="deals-card__text">
                                    {deptDeal.TITLE}
                                    <span>{deptDeal.TEASER}</span>
                                </h3>
                            </a>
                        </div>
                    )}    
                </div>
                <div className="product-slider__loader">
                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                </div>
            </div>
        </div>
    }

    receiveNotification_SfCustomer(context: SfCustomerMyAccountModel | any) {

        if (context !== undefined && context.LoggedIn && context.CustomerID !== undefined && context.CustomerID > 0) {
            if (this.state.sfCustomer == null ||
                (!this.state.sfCustomer.LoggedIn || this.state.sfCustomer.CustomerID !== context.CustomerID)) {
                this.initDealsSlider(context.LoggedIn);
                this.getRecommendedProducts();
                this.setState({
                    isLoggedIn: true,
                    sfCustomer: context
                });
                
            }
            
        } else {
            this.setState({
                isLoggedIn: false,
                sfCustomer: context
            });
            //(window as any).dealsSliderLoggedOut();
            this.initDealsSlider(false);
        }

    }

    //This one is for testing only
    //receiveNotification_NEW(context) {
    //    //this.setState((state) => {
    //    //    // Important: read `state` instead of `this.state` when updating.
    //    //    return { count: state.count + 1 }
    //    //});

    //    if (context instanceof SfCustomerMyAccountModel) {
    //        this.setState({
    //            sfCustomer: context as SfCustomerMyAccountModel
    //        });
    //    } else {
    //        this.setState({
    //            contextState: context
    //        });
    //    }
    //    console.log("CustomerMainComponent receiveNotification_NEW: " + context);

//    {
//stateContext !== undefined &&
//    <h3>{stateContext} </h3>
//}

    //}

    private static renderContents(sfCustomer: SfCustomerMyAccountModel, recommendedProducts: any[], stObserver: ObserverModel | undefined, stateContext: string | undefined) {
        var recommendedProdsRendered: JSX.Element[] | null = null;
        if (recommendedProducts != null) {
            recommendedProdsRendered = recommendedProducts.map((product) => {
                return <a key={"recA_" + product.PRODUCT_ID} href={GetBaseUrl() + "product/detail/" + product.PRODUCT_ID + "/" + product.URL_NAME} >
                    <img key={"recImg_" + product.PRODUCT_ID} src={product.STANDARD_IMAGE} alt={product.SEO_IMAGE_ALT_TAG} />
                       </a>
            });
        }
        return <div className="home-card home-card--greeting">
                <div className="user-greeting">
                    <div className="user-greeting__intro">
                    <div className="user-greeting__image">                      

                        <img src="webcontent/icons/user-placeholder.jpg" />

                    </div>
                    <div className="user-greeting__hello">
                        {/* var greetingTranslation is supplied from the string resource on the Home.cshtml page */}
                        <h3>{(window as any).greetingTranslation}, <span>{sfCustomer.CustomerName} {sfCustomer.CustomerLastName}</span></h3>
                            { sfCustomer.CUSTOMER_SINCE_YEAR > 0 &&
                            <p>{(window as any).stringResourcesHomepage.customerSince} {sfCustomer.CUSTOMER_SINCE_YEAR}</p>
                            }
                        </div>
                    </div>
                <a href="m/orders" className="user-greeting__recent-orders">{(window as any).stringResourcesHomepage.seeYourRecentOrders} &raquo;</a>
                    {recommendedProducts != null && <div className="user-greeting__recommended">
                    <h3>{(window as any).stringResourcesHomepage.recommendedForYou}</h3>
                        <div className="user-greeting__recommended-items">
                        {recommendedProdsRendered}
                        </div>
                    </div>}
                </div>
            </div>
    }

    componentDidMount() {
        //setTimeout(() => {
        //    this.getGetDeptDealsNewsFeedData();
        //}, 3000);
        this.getGetDeptDealsNewsFeedData();
        //this.checkIfLoggedIn();
        this.initObserverAndSubscribeToSfCustomerSubject();
    }
    checkIfLoggedInHeaderApp() {
        setTimeout(() => {
            try {
                (window as any).angularHeaderSubscribeToSfCustCheckLogin.zone.run(() => {
                    (window as any).angularHeaderSubscribeToSfCustCheckLogin
                        .componentFn(null)
                        .subscribe(result => {
                            if (result !== undefined && result.LoggedIn && result.CustomerID !== undefined && result.CustomerID > 0) {
                                this.setState({
                                    isLoggedIn: true,
                                    sfCustomer: result,
                                }, function () { });                     
                                (window as any).dealsSliderLoggedIn();
                            } else {                               
                                console.log("NOT logged in");                                
                                this.setState({
                                    isLoggedIn: false,
                                    sfCustomer: result,
                                }, function () { });
                                (window as any).dealsSliderLoggedOut(); 
                            }
                            console.log(result);
                        })
                });
            } catch (error) {
                console.error(error);
            }

        }, 750);
    }

    checkIfLoggedIn() {

        return fetch(GetBaseUrl() + CustomerMainComponent.SfCustomerCheckLoginURL, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                // "Content-Type": "application/x-www-form-urlencoded",
            }
        })
            .then((response) => response.json())
            .then((result) => {
                
                if (result !== undefined && result.LoggedIn && result.CustomerID !== undefined && result.CustomerID > 0) {
                    this.initDealsSlider(result.LoggedIn);
                    this.getRecommendedProducts();
                    //(window as any).dealsSliderLoggedIn();
                    this.setState({
                        isLoggedIn: true,
                        sfCustomer: result,
                    },
                        function () {

                        });
                    //console.log("user is logged in");
                    
                } else {
                    //console.log("NOT logged in");
                    //(window as any).dealsSliderLoggedOut();
                    this.initDealsSlider(false);
                    this.setState({
                        isLoggedIn: false,
                        sfCustomer: result,
                    },
                        function () {

                        });
                }

            })
            .catch((error) => {
                console.error(error);
            });
    }

    _sliderStatus: number = 0;//0 not set; 1 set to LoggedOut; 2 set to LoggedIn;
    initDealsSlider(loggedIn: boolean | undefined) {
        if (this._deptDealFinishedLoading) {
            if (loggedIn === undefined || loggedIn === null) {
                loggedIn = this.state.isLoggedIn;
            }
            if (!loggedIn && this._sliderStatus !== 1) {
                this._sliderStatus = 1;
                setTimeout(() => {
                    if ((window as any).dealsSliderLoggedOut) {
                        (window as any).dealsSliderLoggedOut();
                    }
                }, 150);
            } else if (loggedIn && this._sliderStatus !== 2) {
                this._sliderStatus = 2;
                setTimeout(() => {
                    if ((window as any).dealsSliderLoggedOut) {
                        (window as any).dealsSliderLoggedIn();
                    }
                }, 150);
            }

        }
        

    }

    _deptDealFinishedLoading: boolean = false;
    getGetDeptDealsNewsFeedData() {

        return fetch(GetBaseUrl() + CustomerMainComponent.GetDeptDealsNewsFeedURL, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                // "Content-Type": "application/x-www-form-urlencoded",
            }
        })
            .then((response) => response.json())
            .then((result) => {
                if (result !== undefined && result !== null && result.length>0) {
                    this._deptDealFinishedLoading = true;
                    this.initDealsSlider(undefined);
                    this.setState({
                        
                        detpDeals: result,
                    },
                        function () {

                        });

                }

            })
            .catch((error) => {
                console.error(error);
            });
    }

    private recommendedFinished: boolean = false;
    getRecommendedProducts(data = {}) {
        // Default options are marked with *
        if (typeof data === "undefined") {
            data = {};
        }
        return fetch(GetBaseUrl() + CustomerMainComponent.RecommendedProductsUrl,
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'same-origin', // no-cors, cors, *same-origin
                cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'client', // no-referrer, *client
                body: JSON.stringify(data), // body data type must match "Content-Type" header
            }).then(response => response.json()).then(result => {
                this.recommendedFinished = true;
                this.setState({
                    recommendedProducts: result
                });
        }).catch(err => console.log(err));
    }

    localObserver: ObserverModel;
    initObserverAndSubscribeToSfCustomerSubject() {
        this.localObserver = new ObserverModel();
        this.localObserver.id = 28;
        //this.localObserver.update = this.receiveNotification_NEW;
        this.setState({
            stateObserver: this.localObserver
        },
            function () {

            })
        try {
            if ((window as any).$SfCustomerSubject !== undefined) {
                var customSubj = (window as any).$SfCustomerSubject
                customSubj.addObserver(this.localObserver);
            }
        } catch (exception) {
            //TODO: log exception in the logger
            console.log(exception);
        }


    }
}

var _baseURL = null;
function GetBaseUrl() {

    if (_baseURL == null) {
        _baseURL = document.getElementsByTagName('base')[0].href;
    }
    return _baseURL;
}
