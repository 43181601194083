import * as React from "react";
import * as ReactDOM from "react-dom";
import { CustomerMainComponent } from "./components/CustomerMainComponent";


/*The CustomerMainComponent reference pointer is accessible from the windwow object via window.CustomerMainComponent
 or (window as any).CustomerMainComponentCustomerMainComponent in .tsx 
 Please refer the following example: https://brettdewoody.com/accessing-component-methods-and-state-from-outside-react/
 */


////old method
//const mainCustomerRectApp = ReactDOM.render(
//    <CustomerMainComponent />,
//    document.getElementById("divCustomerReactApp")
//);

//(window as any).$mainCustomerRectApp = mainCustomerRectApp;

//new method

ReactDOM.render(<CustomerMainComponent
    ref={(CustomerMainComponent) => { (window as any).CustomerMainComponent = CustomerMainComponent }} />,
    document.getElementById("divCustomerReactApp"));